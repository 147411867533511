import React from "react";
import { IPage } from "models/navigation/page";
import { adminDrawerOptions } from "models/navigation/adminDrawerOptions";
import { clinicianDrawerOptions } from "models/navigation/clinicianDrawerOptions";
import { technicianDrawerOptions } from "models/navigation/technicianDrawerOptions";

export const navDrawerOptions: IPage[] = [
    ...adminDrawerOptions,
    ...clinicianDrawerOptions,
    ...technicianDrawerOptions
]