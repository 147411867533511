import React from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Props } from "models/interfaces/props";
import { lightTheme } from "app/styling/themes/light";
import {enGB} from 'date-fns/locale';

const ThemeLayout: React.FC<Props> = ({children}) => {
    
    return (
        <ThemeProvider theme={lightTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <CssBaseline enableColorScheme/>
                {children}
            </LocalizationProvider>
        </ThemeProvider>
    )
}
export { ThemeLayout }