import React, { useCallback, useState } from 'react';
import { Avatar, Divider, Grid, IconButton, ListItemButton, Menu, Typography } from "@mui/material";
import { MoreVertRounded } from "@mui/icons-material";
import { getInitials } from "app/helpers/stringHelper";
import { msalInstance } from "features/authentication/config/msal";
import { UserType } from "models/lookup/userType";
import { useUserContext } from "app/contexts/userContext";
import { userApi } from "features/users/api/userApi";

export interface UserSummaryProps {
    open: boolean;
    firstName: string;
    surname: string;
    color?: string;
}

const UserSummary: React.FC<UserSummaryProps> = ({ open, firstName, surname, color = "textPrimary" }) => {

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const openMenu = Boolean(anchor);

    const { user, updateUser } = useUserContext();

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchor(null);
    }, []);

    const changeUserType = useCallback((userType: UserType) => () => {
        userApi.changeUserType(userType).then((response) => updateUser(response.data));
    }, [updateUser])
    
    return (
        <>
            <Grid container spacing={1} alignItems={"center"} flexWrap={"nowrap"}>
                <Grid item>
                    <Avatar>{getInitials([firstName, surname])}</Avatar>
                </Grid>
                {open &&
                    <>
                        <Grid item xs>
                            <Typography variant={"body1"} color={color}>{firstName} {surname}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClick}>
                                <MoreVertRounded/>
                            </IconButton>
                        </Grid>
                    </>
                }
            </Grid>
            <Menu
                anchorEl={anchor}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={"menu"}
            >
                {user && user.email.includes("@nudgereality.com") &&
                    <>
                        <ListItemButton onClick={changeUserType(UserType.Admin)}>Change to Admin</ListItemButton>
                        <ListItemButton onClick={changeUserType(UserType.None)}>Change to None</ListItemButton>
                        <ListItemButton onClick={changeUserType(UserType.Technician)}>Change to
                            Technician</ListItemButton>
                        <ListItemButton onClick={changeUserType(UserType.Clinician)}>Change to
                            Clinician</ListItemButton>
                        <Divider/>
                    </>
                }
                <ListItemButton onClick={() => msalInstance.logoutRedirect()}>Logout</ListItemButton>
            </Menu>
        </>
    )
}
export { UserSummary }