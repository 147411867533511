import { User } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { axiosApi } from "app/axios/axios";

class AuthenticationApi {
    private controllerName: string = "api/Authentication"
    
    public async validateUser(accountInfo: AccountInfo): Promise<User> {
        const url: string = `${this.controllerName}/Validate`;
        
        return await axiosApi.post(url, JSON.stringify(accountInfo))
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }
    
    public async get(){
        return await axiosApi.get(this.controllerName).then(() => {
            return;
        })
    }
}

const authenticationApi = new AuthenticationApi();
export { authenticationApi }