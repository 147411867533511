import React from "react";

import { User } from "features/users/models/user";
import { AccountInfo } from "@azure/msal-browser";

type UserContextState = {
    user: undefined | User,
    account: undefined | AccountInfo,
    updateUser: (user: User) => void,
    updateAccount: (account: AccountInfo) => void
}


export const UserContext = React.createContext<UserContextState>({
    user: undefined ,
    account: undefined,
    updateUser: () => {},
    updateAccount: () => {}
})

export const useUserContext = () => React.useContext(UserContext)