import React, { useState } from 'react';
import { Avatar, AvatarGroup, Grid, IconButton, SxProps, Tooltip, Typography } from "@mui/material";
import { DatePickerRange } from "components/input/datePickerRange";
import { AddRounded } from "@mui/icons-material";
import { useUserContext } from "app/contexts/userContext";

import WelcomeIllustration from "graphics/illustrations/welcome.svg";

const WelcomeMessage: React.FC = () => {
    
    const { user } = useUserContext();
    
    return (
        <Grid container alignItems={"center"} spacing={4} position={"relative"}>
            <Grid item>
                <Typography variant={"h5"} color={"white"}>Good Morning, {user && user.firstName}!</Typography>
                <Typography variant={"body1"} color={"rgba(255,255,255,0.7)"}>Here is an overview of your day</Typography>
            </Grid>
            <Grid item position={"absolute"} height={"180%"} right={-60}>
                <img src={WelcomeIllustration} alt={"Welcome Illustration"} width={"100%"} height={"100%"}/>
            </Grid>
        </Grid>
    )
}
export { WelcomeMessage }