import React, { useEffect, useState } from 'react';
import { Props } from "models/interfaces/props";
import { Box } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { useUserContext } from "app/contexts/userContext";
import { UserType } from "models/lookup/userType";
import { LoadingLayout } from "app/layouts/loadingLayout";
import { ClinicianBroker, ClinicianContext } from "app/contexts/clinicianContext";
import { Clinician } from "features/clinician/models/clinician";

const ClinicianLayout: React.FC<Props> = ({ children }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [clinician, setClinician] = React.useState<Clinician | undefined>(undefined)

    const { user } = useUserContext();

    useEffect(() => {
        setLoading(user !== null && user?.type !== UserType.Clinician && user?.type !== UserType.Admin)
    }, [user])

    return (
        <ClinicianContext.Provider
            value={{ 
                clinician: clinician,
                updateClinician: setClinician
            }}
        >
            <ClinicianBroker>
                <LoadingLayout loading={loading}>
                    <Box display={"flex"} flexGrow={1} minHeight={"100.0vh"}>
                        <NavDrawer/>
                        <Box component={"main"} flexGrow={1} p={3} display={"inline-flex"}>
                            {children}
                        </Box>
                    </Box>
                </LoadingLayout>
            </ClinicianBroker>
        </ClinicianContext.Provider>
    )
}
export { ClinicianLayout }