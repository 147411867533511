import { Pagination } from "models/table/pagination";
import { UserFilters } from "features/users/models/userFilters";
import { isEmptyOrWhiteSpace } from "app/helpers/stringHelper";
import { BaseFilters } from "models/filters/baseFilters";
import { DeviceFilters } from "features/device/models/deviceFilters";
import { AuditFilters } from "models/audit/auditFilters";
import {MetricFilters} from "../../features/metrics/models/metricFilters";

export class UrlBuilder {
    
    baseUrl: string = "";
    url: string = "";
    firstOption: boolean = true;
    
    constructor(url: string) {
        this.baseUrl = url;
        
        this.reset();
    }
    
    public withPagination(pagination: Pagination): UrlBuilder {
        this.applyPrefix();
        this.url += `PageSize=${pagination.pageSize}&CurrentPage=${pagination.page + 1}`
        
        return this;
    }
    
    public withBaseFilters(filters: BaseFilters): UrlBuilder {
        
        if(!isEmptyOrWhiteSpace(filters.search)){
            this.applyPrefix()
            this.url += `Search=${filters.search}`
        }
        
        return this;
    }

    public withAuditFilters(filters: AuditFilters): UrlBuilder {

        if(filters.creationFrom){
            this.applyPrefix()
            this.url += `CreationFrom=${filters.creationFrom.toLocaleDateString()}`
        }

        if(filters.creationTo){
            this.applyPrefix()
            this.url += `CreationTo=${filters.creationTo.toLocaleDateString()}`
        }

        return this;
    }
    
    public withUserFilters(filters: UserFilters): UrlBuilder {
        
        this.withBaseFilters(filters);
        
        if(filters.active){
            this.applyPrefix()
            this.url += `Active=${filters.active}`
        }

        if(filters.claimed){
            this.applyPrefix()
            this.url += `Claimed=${filters.claimed}`
        }
        
        return this;
    }

    public withDeviceFilters(filters: DeviceFilters): UrlBuilder {

        this.withBaseFilters(filters);
        
        if(filters.assigned){
            this.applyPrefix()
            this.url += `Assigned=${filters.assigned}`
        }
        
        return this;
    }

    public withMetricFilters(filters: MetricFilters): UrlBuilder {

        this.withBaseFilters(filters);

        this.applyPrefix()
        this.url += `CreatedAfter=${filters.createdAfter.toDateString()}`
        this.applyPrefix()
        this.url += `CreatedBefore=${filters.createdBefore.toDateString()}`
        
        return this;
    }

    public withRoute(route: string): UrlBuilder {
        this.url += `/${route}`;

        return this;
    }
    
    public withQuery(query: string): UrlBuilder {
        this.applyPrefix();
        this.url += query;
        
        return this;
    }
    
    public build(): string {
        const output = this.url;
        this.reset()
        
        return output;
    }
    
    private reset() {
        this.url = this.baseUrl;
        this.firstOption = true;
    }
    
    private applyPrefix() {
        this.url += this.firstOption ? "?" : "&";
        this.firstOption = false;
    }
}