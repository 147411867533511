import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
    Box,
    CSSObject,
    Divider, Grid, ListItemButton,
    styled, Tab, Tabs,
    Theme, Typography, useTheme
} from '@mui/material';
import MuiDrawer from "@mui/material/Drawer"
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";

import { elevation } from "app/styling/themes/layout";
import { UserSummary } from "components/layouts/userSummary";
import { useUserContext } from "app/contexts/userContext";
import { IPage } from "models/navigation/page";
import { navDrawerOptions } from "models/navigation/navDrawerOptions";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variant: "contained",
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavDrawer: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    
    const [route, setRoute] = React.useState<string>(location.pathname);
    const [open, setOpen] = useState<boolean>(true);

    const { user } = useUserContext()
    
    useEffect(() => {
        setRoute(location.pathname)
    }, [location])

    const toggleDrawer = () => {
        setOpen(prevState => !prevState);
    };

    const handleChange = useCallback((event: React.SyntheticEvent, value: string) => {
        setRoute(value)
        navigate(value)
    }, [])
    
    const filterByType = useCallback((navOptions: IPage[]) => {
        
        if(user === undefined)
            return [];
        
        return navOptions.filter(n => n.userTypes.includes(user.type));
    }, [user])

    
    const navOptions = filterByType(navDrawerOptions);

    return (
        <Drawer variant="permanent" open={open} PaperProps={{ elevation: elevation }}>
            <Box maxHeight={60} p={2} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                <Typography color={"primary"} variant={"h4"}>{open ? "VIRTUE" : "V"}</Typography>
            </Box>
            <Divider/>
            <Box p={1}>
                { user &&
                        <UserSummary 
                            open={open}
                            surname={user.surname}
                            firstName={user.firstName}/>
                }
            </Box>
            <Divider/>
            <Box flexGrow={1}>
                <Tabs
                    orientation={"vertical"}
                    variant={"scrollable"}
                    value={route}
                    onChange={handleChange}
                >
                    {navOptions.map((page) => (
                        <Tab key={page.name} label={
                            <Grid container spacing={2} alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item display={"inline-flex"}>
                                    {page.icon}
                                </Grid>
                                {open &&
                                    <Grid item xs>
                                        <Typography textAlign={"left"} variant={"body1"} noWrap>{page.name}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        } value={page.route} color={"white"}/>
                    ))}
                </Tabs>
            </Box>
            <Divider/>
            <Box>
                <ListItemButton onClick={toggleDrawer}>
                    <Grid container spacing={2} alignItems={"center"} flexWrap={"nowrap"} pt={1} pb={1}>
                        <Grid item display={"inline-flex"}>
                            {open ? <ChevronLeft color={"action"}/> : <ChevronRight color={"action"}/>}
                        </Grid>
                        {open &&
                            <Grid item xs>
                                <Typography textAlign={"left"} variant={"body1"} color={"textSecondary"}
                                            noWrap>Collapse</Typography>
                            </Grid>
                        }
                    </Grid>
                </ListItemButton>
            </Box>
        </Drawer>
    )
}
export { NavDrawer }

