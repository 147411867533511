import React from 'react';
import { Props } from "models/interfaces/props";
import { AppBar, Box, CircularProgress, Toolbar } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { UserAppBar } from "features/users/components/userAppBar";

export interface LoadingLayoutProps extends Props {
    loading: boolean;
}

const LoadingLayout: React.FC<LoadingLayoutProps> = ({loading, children}) => {

    return (
        loading ?
            <Box display={"flex"} flexGrow={1} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color={"primary"}/>
            </Box>
            : children
    )
}
export { LoadingLayout }