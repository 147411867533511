import React from "react";
import { IPage } from "models/navigation/page";

import {
    AccountCircleOutlined, DashboardCustomizeOutlined,
    DashboardOutlined,
    DashboardRounded,
    DevicesOtherRounded,
    MedicalInformationRounded,
    ReceiptLongRounded
} from "@mui/icons-material";
import { UserType } from "models/lookup/userType";

export const clinicianDrawerOptions: IPage[] = [
    {
        name: "Dashboard",
        route: "/clinician",
        icon: <DashboardOutlined/>,
        userTypes: [UserType.Clinician]
    },
    {
        name: "Patients",
        route: "/clinician/patients",
        icon: <AccountCircleOutlined/>,
        userTypes: [UserType.Clinician]
    },
    {
        name: "Programmes",
        route: "/clinician/programmes",
        icon: <ReceiptLongRounded/>,
        userTypes: [UserType.Clinician]
    },
    {
        name: "Templates",
        route: "/clinician/templates",
        icon: <DashboardCustomizeOutlined/>,
        userTypes: [UserType.Clinician]
    },
]