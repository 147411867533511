import React, { useCallback, useState } from 'react';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { patientDirectoryColumns } from "features/clinician/models/patientDirectoryColumns";
import { flatten } from "app/helpers/objectHelper";
import { Patient } from "features/patient/models/patient";
import { UserFilters } from "features/users/models/userFilters";
import { Pagination } from "models/table/pagination";
import { nameof } from "app/helpers/stringHelper";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type PatientDirectoryProps = {
    patients: Patient[];
    filters: UserFilters;
    pagination: Pagination;
}

const PatientDirectory: React.FC<PatientDirectoryProps> = ({patients, filters, pagination}) => {
    
    const [pageSizeOptions] = useState<number[]>([20])
    
    const navigate = useNavigate();
    
    const viewPatientProfile = useCallback((patientId: string) => () => {
        navigate(`/clinician/patients/${patientId}`)
    }, [navigate])
    
    const viewProfile: GridColDef = {
        headerName: "", field: nameof<Patient>("id"),
        align: "center",
        renderCell: (params) =>
            <IconButton color={"primary"} onClick={viewPatientProfile(params.value)}><ArrowForwardIosRounded/></IconButton>
    } 
    
    return (
        <DataGrid
            autoHeight
            columns={[...patientDirectoryColumns, viewProfile]}
            rows={patients.map((p) => flatten(p))}
            disableColumnMenu
            pagination
            paginationModel={pagination}
            pageSizeOptions={pageSizeOptions}
        />
    )
}
export { PatientDirectory }