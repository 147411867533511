import React from 'react';
import { Card, CardContent, Container, Grid, IconButton, useTheme } from "@mui/material";
import { WelcomeMessage } from "components/heading/welcomeMessage";
import { IconCard } from "components/cards/iconCard";
import {
    BrowseGalleryRounded,
    BubbleChartRounded, ChevronRightRounded,
    VerifiedRounded,
    WatchLaterRounded
} from "@mui/icons-material";

const ClinicianDashboard: React.FC = () => {

    const theme = useTheme();

    return (
        <Container>
            <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={12} sm={12} md={12} lg={4.5} xl={3.5}>
                    <Card sx={{
                        backgroundColor: theme.palette.primary.main,
                    }}>
                        <CardContent>
                            <WelcomeMessage/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
export { ClinicianDashboard }