import React from 'react';
import { Container, Typography } from "@mui/material";

const PatientDashboard: React.FC = () => {
    return (
        <Container>
            <Typography>Patient Dashboard</Typography>
        </Container>
    )
}
export { PatientDashboard }