import React from "react";
import { IPage } from "models/navigation/page";

import {
    AccountCircleOutlined, DashboardCustomizeOutlined,
    DashboardOutlined,
    DashboardRounded,
    DevicesOtherRounded,
    MedicalInformationRounded,
    ReceiptLongRounded
} from "@mui/icons-material";
import { UserType } from "models/lookup/userType";

export const technicianDrawerOptions: IPage[] = [
    {
        name: "Dashboard",
        route: "/technician",
        icon: <DashboardRounded/>,
        userTypes: [UserType.Technician]
    },
    {
        name: "Devices",
        route: "/technician/devices",
        icon: <DevicesOtherRounded/>,
        userTypes: [UserType.Technician]
    }
]