import React from "react";
import { IPage } from "models/navigation/page";

import {
    AccountCircleOutlined, DashboardCustomizeOutlined,
    DashboardOutlined,
    DashboardRounded,
    DevicesOtherRounded,
    MedicalInformationRounded,
    ReceiptLongRounded
} from "@mui/icons-material";
import { UserType } from "models/lookup/userType";

export const adminDrawerOptions: IPage[] = [
    {
        name: "Dashboard",
        route: "/admin",
        icon: <DashboardOutlined/>,
        userTypes: [UserType.Admin]
    },
]