import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { authConfig } from "features/authentication/config/auth";
import { msalInstance } from "features/authentication/config/msal";

class MsalApi {
    public async getSilentToken(username: string) {
        const currentAccount: AccountInfo | null = msalInstance.getAccountByUsername(username);
        const tokenResponse: AuthenticationResult = await msalInstance.acquireTokenSilent({
            scopes: authConfig.scopes,
            // @ts-ignore
            account: currentAccount
        })
        
        return tokenResponse;
    }
}
const msalApi = new MsalApi();
export { msalApi }