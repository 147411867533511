import React, { useEffect, useState } from 'react';
import { Props } from "models/interfaces/props";
import { AppBar, Box, Toolbar } from "@mui/material";
import { NavDrawer } from "components/navigation/navDrawer";
import { UserAppBar } from "features/users/components/userAppBar";
import { useUserContext } from "app/contexts/userContext";
import { UserType } from "models/lookup/userType";
import { LoadingLayout } from "app/layouts/loadingLayout";

const UserLayout: React.FC<Props> = ({children}) => {

    const [loading, setLoading] = useState<boolean>(true)
    
    const { user } = useUserContext();
    
    useEffect(() => {
        setLoading(user !== null && user?.type !== UserType.None)
    }, [user])
    
    return (
        <LoadingLayout loading={loading}>
            <Box display={"flex"} flexGrow={1} minHeight={"inherit"}>
                <UserAppBar/>
                <Box component={"main"} flexGrow={1} p={3} alignContent={"center"}>
                    <Toolbar/>
                    {children}
                </Box>
            </Box>
        </LoadingLayout>
        
    )
}
export { UserLayout }