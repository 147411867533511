import React, { useCallback } from 'react';
import { Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { UserType } from "models/lookup/userType";
import { userApi } from "features/users/api/userApi";
import { useUserContext } from "app/contexts/userContext";
import snackbar from "app/helpers/snackbar";

const UserDashboard: React.FC = () => {

    const { updateUser, user } = useUserContext();

    const handleProceedClick = useCallback(() => {
        userApi.changeUserType(UserType.Clinician)
            .then((response) => updateUser(response.data))
            .catch((e) => snackbar.error(e.response.data));
    }, [updateUser])

    return (
        <Container maxWidth={'md'} sx={{ minHeight: "inherit" }}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant={'h5'}>Welcome to the VIRTUE Portal</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'}>Your comprehensive platform for managing patient care and
                                    VR-based
                                    therapy programs.
                                    With Virtue, you can easily add or remove patients from the system,
                                    assign them to immersive virtual reality therapy sessions, and monitor their
                                    progress in
                                    real-time.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'}>
                                    Our user-friendly interface allows healthcare professionals to track key metrics and
                                    ensure each patient is on the right path to recovery.
                                    Whether you're managing a single patient or a large cohort,
                                    Virtue provides the tools you need to deliver personalized,
                                    effective care efficiently through cutting-edge VR technology.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"flex-end"}>
                            {user?.customerId ?
                                <Button size={"large"} onClick={handleProceedClick}>
                                    Proceed to Clinician Space
                                </Button>
                                :
                                <Button size={"large"} href={"mailto:info@nudgereality.com?subject=VIRTUE%20Access%20Request&body=Hello%2C%0A%0AI%20would%20like%20access%20to%20%3Corganisation-name%3E%20on%20the%20VIRTUE%20Portal.%0A%0AKind%20regards%2C%0A%3Cyour-name%3E"}>
                                    Request Access
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}
export { UserDashboard }