import { User } from "features/users/models/user";
import { axiosApi } from "app/axios/axios";
import { InviteUserRequest } from "features/users/api/request/inviteUserRequest";
import { Pagination } from "models/table/pagination";
import { UrlBuilder } from "app/builders/urlBuilder";
import { UserType } from "models/lookup/userType";
import { AxiosResponse } from "axios";

class UserApi {
    private urlBuilder: UrlBuilder;

    constructor() {
        this.urlBuilder = new UrlBuilder("api/user")
    }

    public async getPaginatedUsers(pagination: Pagination): Promise<User[]> {
        const url: string = this.urlBuilder
            .withPagination(pagination)
            .build();

        return await axiosApi.get(url)
            .then(response => response.data)
            .catch(e => {
                return e;
            });
    }

    public async updateUser(user: User): Promise<User> {

        return await axiosApi.post(this.urlBuilder.build(), JSON.stringify(user))
            .then(response => response.data)
            .catch(e => e)

    }

    public async inviteUser(request: InviteUserRequest): Promise<User> {
        const url: string = this.urlBuilder.withRoute("InviteUser").build()

        return await axiosApi.post(url, JSON.stringify(request)).then((response) => {
            return response.data;
        }).catch((e) => {
            return e;
        })
    }

    public async changeUserType(userType: UserType): Promise<AxiosResponse<User>> {

        const url: string = this.urlBuilder.withRoute("ChangeUserType").withRoute(userType.toString()).build()

        return await axiosApi.post(url);
    }

    public async changeUserCustomer(userId: string, customerId: string): Promise<AxiosResponse> {

        const url: string = this.urlBuilder
            .withRoute(userId)
            .withRoute("customer")
            .withRoute(customerId)
            .withRoute("update")
            .build()

        return await axiosApi.post(url);
    }
}

const userApi = new UserApi();
export { userApi }